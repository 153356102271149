<template>
  <div class="px-5" id="page-shortlink">

    <p>{{ $t('INVOICE.DOWNLOADING') }}</p>
  </div>
</template>
  
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'PdfLink',
  components: {

  },
  computed: {
    ...mapGetters(['isMemAuthenticated', 'currentMember']),

    pdf_path() {
      return get_base_url() + `/dlpdf/${this.linkstr}.pdf`;
    }
  },
  async mounted() {
    this.linkstr = this.$route.params.linkstr;

    setTimeout(()=>{
      this.download_pdf();
    }, 1000);
  },
  data() {
    return {
      linkstr: null
    };
  },
  watch: {

  },
  methods: {

    async download_pdf() {
      try {
        downloadWithAxios(get_base_url() + `/dlpdf/${this.linkstr}`, 'medlem.pdf');
      }
      catch (err) {
        console.error('get_pdf error', err);
      }
    }
  }
};
</script>
  